const popper  = require('popper.js');

require('../vendor/stack-theme/app-assets/js/core/libraries/bootstrap.min.js');
require('../vendor/stack-theme/app-assets/vendors/js/ui/perfect-scrollbar.jquery.min.js');
require('../vendor/stack-theme/app-assets/vendors/js/ui/unison.min.js');
require('../vendor/stack-theme/app-assets/vendors/js/ui/blockUI.min.js');
require('../vendor/stack-theme/app-assets/vendors/js/ui/jquery-sliding-menu.js');
require('../vendor/stack-theme/app-assets/vendors/js/forms/icheck/icheck.min.js');
require('../vendor/stack-theme/app-assets/vendors/js/forms/validation/jqBootstrapValidation.js');
require('../vendor/stack-theme/app-assets/js/core/app.min.js');
require('../vendor/stack-theme/app-assets/js/core/app-menu.min.js');
require('../vendor/stack-theme/app-assets/js/scripts/forms/form-login-register.js');
require('../vendor/stack-theme/app-assets/vendors/js/forms/select/select2.full.min.js');
require('../vendor/stack-theme/app-assets/vendors/js/forms/spinner/jquery.bootstrap-touchspin.js');
require('../vendor/stack-theme/app-assets/vendors/js/pickers/dateTime/moment-with-locales.min');
require('../vendor/stack-theme/app-assets/vendors/js/pickers/dateTime/bootstrap-datetimepicker.min.js');
